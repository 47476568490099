import React, { Component } from 'react';
import { WFInput } from '../../components/wireframes/modules';

class Start extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
        };
        this.update = this.update.bind(this);
    }

    update(data) {
        this.setState(data);
    }

    render() {
        const { email } = this.state;
        const { lang, isFetching } = this.props;

        if(isFetching) {
            return (
                    <div className="wf wf-br">
        
                        <h2>Sending </h2>
                        <p>Please check your email client now...</p>
        
                        <hr />
        
                        <div className="wf-loader"></div>
        
                    </div>
            );
        } else {
            return (
                <div className='wf'>
    
                    <h2>Request link</h2>
                    <p>One-click authorisation link will be sent to your email</p>
    
                    <hr />

                    <WFInput
                        name={'email'}
                        label={'Enter your email'}
                        submit={(value) => { this.update(value) }}
                        validation={(value) => {
                            if (!value.includes('@')) return false;
                            if (!value.includes('.')) return false;
                            return value.length < 100;
                        }}
                    />
    
                    <button onClick={(e) => {
                        e.preventDefault();
                        this.props.create_auth_session({
                            email,
                            type: 'START'
                        });
                    }}>
                        Send link
                    </button>

                </div>
            );
        }

        
    }
}
export default Start;