import React, { Component } from 'react';
import { round } from 'lodash';

import base from '../../config';


function progress_perc ({ count, timeout }) {
    const perc = round(count / timeout * 100, 0);
    return perc > 100 ? 100 : perc;
}

class Waiting extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { lang, email, auth_count } = this.props;
        const timeout = (base.allow.poll_timeout / 1000);

        return (
            <div className="wf">
                    <h2>Click on link in email</h2>

                    <p>Sent to: {email}</p>

                    <p>Please check your email client and click on the access link. This access email link will expire in <mark>{round(timeout - auth_count, 0)}</mark> seconds.</p>

                    <div className='wf-loader'></div>

            </div> 
        );
    }
}
export default Waiting;