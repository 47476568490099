import React, { Component } from 'react';
import base from '../../config';

class Timeout extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { email } = this.props;
        const timeout = (base.allow.poll_timeout / 1000);
        return (
            <div className="wf">
                <h2>Your access link timed out</h2>
                <p>Sent to: {email}</p>
                <p>Sorry, you failed to be authorised because you didn't click on the access link within <mark>{timeout}</mark> seconds.</p>
                <button onClick={(e) => {
                    e.preventDefault();
                    this.props.set_auth_status('START');
                }}>
                    Try again
                </button>
            </div>
        );
    }
}
export default Timeout;