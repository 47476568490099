import React, { Component } from 'react';
import { round } from 'lodash';
import { getId }  from '../../utils/url';

const timeout = 5;

class Success extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
        }
    }

    componentDidMount() {
        const interval = setInterval(() => {
            const { count } = this.state;
            if (count >= timeout) {
                clearInterval(interval);
                const id = getId(this.props.history);
                this.props.history.push(id === '/' ? '/pools' : '');
            } else {
                this.setState({ count: count + 1 });
            }
        }, 1000)
    }

    render() {
        const { count } = this.state;
        
        return (
            <div className="wf">
                <h2>Success!</h2>
                <p>You will be automatically re-directed in <mark>{round(timeout - count, 0)}</mark> seconds or you can click on the button below. </p>
                <button className="primary-button alt" onClick={(e) => {
                    e.preventDefault();
                    const id = getId(this.props.history);
                    this.props.history.push(id === '/' ? '/pools' : '');
                }}>
                    Take me there!
                </button>
            </div> 
        );
    }
}
export default Success;