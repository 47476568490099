import { combineReducers } from 'redux';

import lang from './lang';
import auth from './auth';
import token from './token';

// Global but will change per service
import allow_sessions from './sessions';

//Allow
import allow_users from '../services/allow/reducers/users';
import allow_companys from '../services/allow/reducers/companys';
import allow_pools from '../services/allow/reducers/pools';

//Willow
import willow_landing from '../services/willow/reducers/landing';

// env switch to pick right actions
const main_component = process.env.REACT_APP_MAIN;

let base = {
    lang,
    token,
    auth,
}

if (main_component === 'allow') {
    base = {
        ...base,
        allow_users,
        allow_companys,
        allow_pools,
        allow_sessions,
    }
}

if (main_component === 'willow') {
    base = {
        ...base,
        willow_landing,
    }
}

export default combineReducers(base);