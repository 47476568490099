import set_lang from './lang';

import auth from './auth';
import set_token from './token';

// Global for all but pool_reference will change per service
import allow_sessions from './sessions';

//allow
import allow_users from '../services/allow/actions/users';
import allow_companys from '../services/allow/actions/companys';
import allow_pools from '../services/allow/actions/pools';

//allow
import willow_landing from '../services/willow/actions/landing';

// env switch to pick right actions
const main_component = process.env.REACT_APP_MAIN;

let base = {
    set_lang,
    set_token,
    ...auth.exportMethods(),
    auth_reset: () => ({
        type: 'RESET_AUTH',
    }),
};

if (main_component === 'allow') {
    base = {
        ...base,
        ...allow_users.exportMethods(),
        ...allow_companys.exportMethods(),
        ...allow_pools.exportMethods(),
        ...allow_sessions.exportMethods(),
    }
}

if (main_component === 'willow') {
    base = {
        ...base,
        ...willow_landing.exportMethods(),
    }
}

export default base;
