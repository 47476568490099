/*
* All routing
*/

import Wireframes from '../components/wireframes/Index';

import AllowPools from '../services/allow/pages/Pools';
import AllowPoolDetail from '../services/allow/pages/PoolDetail';
import AllowUserDetail from '../services/allow/pages/UserDetail';
import AllowCompanyDetail from '../services/allow/pages/Settings';
import AllowUsers from '../services/allow/pages/Users';
import AllowSessions from '../services/allow/pages/Sessions';
import AllowSessionDetail from '../services/allow/pages/SessionDetail';
import AllowSettings from '../services/allow/pages/Settings';
import AllowLogin from '../services/allow/pages/Login';

import GoallowGame from '../services/goallow/pages/Game';

import WillowHome from '../services/willow/pages/Home';

let routes = [        {
    name: 'Wireframes',
    path: '/wf',
    component: Wireframes,
    hide: true,
}];

/*
properties:
name - Link display name
icon - feather icon for display
path - route path. This automatically defined in Router 
product - when to show link with in Policy or Rating view
component - The React Page to render
sublinks - The dropdown children links
hide - if true, this sets up the route but doesn't display it. Use for details pages mainly
*/

// env switch to pick right actions
const main_component = process.env.REACT_APP_MAIN;

if (main_component === 'allow') {
    routes = [
        ...routes,
        {
            name: 'Home',
            path: '/',
            component: AllowPools,
        },
        {
            name: 'Login',
            path: '/login/:id',
            hide: true,
            component: AllowLogin,
        },
        {
            name: 'Users',
            path: '/users',
            component: AllowUsers,
        },
        {
            name: 'Sessions',
            path: '/sessions',
            component: AllowSessions,
        },
        {
            name: 'Pools',
            path: '/pools',
            component: AllowPools,
        },
        {
            name: 'PoolDetail',
            path: '/pools/:id',
            component: AllowPoolDetail,
            hide: true,
        },
        {
            name: 'SessionDetail',
            path: '/sessions/:id',
            component: AllowSessionDetail,
            hide: true,
        },
        {
            name: 'UserDetail',
            path: '/users/:id',
            component: AllowUserDetail,
            hide: true,
        },
        {
            name: 'Settings',
            path: '/settings',
            component: AllowSettings,
        },
    ]
};

if (main_component === 'goallow') {
    routes = [
        ...routes,
        {
            name: 'Home',
            path: '/',
            component: GoallowGame,
        },
    ]
};

if (main_component === 'willow') {
    routes = [
        ...routes,
        {
            name: 'Home',
            path: '/',
            component: WillowHome,
        },
    ]
};

export default routes;
