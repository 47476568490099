import React, { Component } from 'react';
import { omit, get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Login from '../auth/Login';

import { getLangSource } from '../lang';
import actions from '../actions';

import { sign_out, get_auth } from '../auth/index';

export default (Page, opts = { 
    is_private: true, 
    show_nav: true, 
    show_footer: false,
}) => {

    class Main extends Component {
        constructor(props) {
            super(props);
            this.state = {
                url: '',
                isFetchingToken: true,
                section: '',
                selectedLang: 'en',
            }
            this.selectLang = this.selectLang.bind(this);
            this.selectEnv = this.selectEnv.bind(this);
            this.signOut = this.signOut.bind(this);
            this.setAuthToken = this.setAuthToken.bind(this);
        }

        async componentWillMount() {
            await this.setAuthToken();
        }

        async setAuthToken() {
            try {
                const auth_data = await get_auth();
                this.setState({ isFetchingToken: false });
                if (!isEmpty(auth_data)) {
                    this.props.set_token({
                        ...auth_data,
                        token: auth_data.access_token,
                    });
                    try {
                        await this.props.auth_get({ id: auth_data.user_reference });
                    } catch(e) {
                        console.log(e);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }

        signOut() {
            sign_out();
            this.props.auth_reset({});
        }

        selectLang(lang) {
            this.setState({
                lang,
            }, () => {
                this.props.set_lang(this.state.lang);
            });
        }

        selectEnv(selectedEnv) {
            this.props.set_environment(selectedEnv);
        }

        render() {

            const { isFetchingToken } = this.state;
            const lang = getLangSource(this.state.lang);
            const { auth = {} } = this.props;

            if (opts.is_private) {
                if (!get(auth, 'selected.user_reference')) return (<Login history={this.props.history} />);
            }

            return (
                <div>
                    <Page {...this.props} />
                </div>
            );
        }
    }

    function mapStateToProps(state) {
        return state;
    }

    function mapDispatchToProps(dispatch) {
        return bindActionCreators({
            ...actions,
        }, dispatch);
    }

    return connect(mapStateToProps, mapDispatchToProps)(Main);
};