import React, { Component } from 'react';


class Failure extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { email } = this.props;
        return (
            <div className="wf">

                <h2>Something went wrong</h2>
                <p>Sent to: {email}</p>
                <p>Sorry, you failed to be authorised to via the access link within the email.</p>
                    <button onClick={(e) => {
                        e.preventDefault();
                        this.props.set_auth_status('START');
                    }}>
                        Try again
                    </button>
            </div> 
        );
    }
}
export default Failure;